// Navbar.js
import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { toast } from 'react-toastify';
import GoogleTranslate from './GoogleTranslate';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  transition: 'opacity 0.5s ease-in-out',
  opacity: 0,
  '&.fade-in': {
    opacity: 1,
  },
}));

export default function Navbar({ theme, onThemeToggle }) {
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('name')
    toast.success("LogOut Success");
    navigate('/login');
  }
  useEffect(() => {
    setFade(true);
  }, []);
  const authToken = localStorage.getItem('authToken');
  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="static" className={fade ? 'fade-in' : ''}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            component={NavLink}
            to="/"
          >
            <HomeIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            KB's TODO
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', // Center items vertically
              marginLeft: 'auto', // Push items to the right
            }}
          >
            <div style={{marginRight:"50px"}}><GoogleTranslate/></div>
            {localStorage.getItem('name')}
            <IconButton onClick={onThemeToggle} style={{marginLeft:'10px'}} color="inherit">
              {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {authToken && authToken.length > 0 ? (
              <Button sx={{ color: '#fff' }} onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <>
                <Button sx={{ color: '#fff' }} component={NavLink} to="/login">
                  Login
                </Button>
                <Button sx={{ color: '#fff' }} component={NavLink} to="/signup">
                  Signup
                </Button>
              </>
            )}
            {/* Add other buttons as needed */}
          </Box>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
